import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';

import HeroSlideShow from './heroSlideShow';

const Header = ({ content, backgroundImage, gallery, additionalClasses }) => {
  const HeroBody = () => (
    <div className="hero-body">
      <div className="container">{content}</div>
    </div>
  );

  if (gallery && backgroundImage) {

    const filteredGalleryForExist = gallery.filter(({ localFile }) => localFile);
    const slider = [
      backgroundImage.hero.fluid,
      ...filteredGalleryForExist.map(({ localFile }) => localFile.hero.fluid),
    ];
    const lightbox = [
      backgroundImage.lightbox,
      ...filteredGalleryForExist.map(({ localFile }) => localFile.lightbox),
    ];
    // Remember to introduce a slight gradient to the hero so that the nav still pops
    return <HeroSlideShow slider={slider} lightBox={lightbox} />;
  }

  return (
    <StyledHero className={`hero ${additionalClasses}`}>
      <HeroBody />
    </StyledHero>
  );
};

Header.propTypes = {
  additionalClasses: PropTypes.string,
  backgroundImage: PropTypes.object,
  gallery: PropTypes.array,
};

Header.defaultProps = {
  additionalClasses: '',
  backgroundImage: null,
  gallery: null,
};

export default Header;

const StyledHero = styled('div')`
  background-color: #efefef;
  .title {
    text-transform: uppercase !important;
    padding-top: 8rem;
    font-weight: 500;
    line-height: 1.33em;
    font-size: 2.5rem;
    text-transform: uppercase;
    @media screen and (max-width: 960px) {
      font-size: 3rem;
      padding-top: 12rem;
      padding-bottom: 8rem;
    }
    @media screen and (max-width: 520px) {
      font-size: 2.5rem;
      padding-top: 7rem;
      padding-bottom: 4rem;
    }
    @media screen and (max-width: 340px) {
      font-size: 2.25rem;
      font-size: 2rem;
      padding-top: 5rem;
      padding-bottom: 2rem;
    }
  }
`;
