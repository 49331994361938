import React, { useState } from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import { StaticQuery, graphql } from 'gatsby';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from '@emotion/styled';
import Select from 'react-select';

import GridItem from './gridItem';

import SearchIcon from '../images/iconfinder_search-stroked_293645.svg';
import SearchCloseIcon from '../images/iconfinder_cross-24_103181.svg';

// Idea is to make toggle global

const HomePageGridComponent = ({
  /* Props */
  properties,
  category,
  location,
  status,
  priceMin,
  priceMax,
  beds,
  baths,
  searchTerm,
  filterToggle,
  sentFromNav,
  /* callbackFNs */
  applyFilter,
  getMoreProperties,
  resetProperties,
  filterToggleFn,
  resetSentFromNav,
}) => {
  /* Options for the select boxes */
  const categoryOpt = [
    { value: null, label: 'All' },
    { value: 'apartment', label: 'Apartment' },
    { value: 'unit', label: 'Unit' },
    { value: 'house', label: 'House' },
    { value: 'commercial', label: 'Commercial' },
  ];
  const locationOpt = [
    { value: null, label: 'All' },
    { value: 'dunedin', label: 'Dunedin' },
    { value: 'central_otago', label: 'Central Otago' },
  ];
  const statusOpt = [
    { value: null, label: 'All' },
    { value: 'available', label: 'Available' },
    { value: 'leased', label: 'Leased' },
    { value: 'withdrawn', label: 'Withdrawn' },
  ];
  const priceMinOpt = [...Array(10)].map((_, i) => ({
    value: (i + 1) * 100 + '',
    label: `${(i + 1) * 100}`,
  }));

  const priceMaxOpt = [...priceMinOpt];
  const bedOpt = [...Array(5)].map((_, i) => ({
    value: i + 1 + '',
    label: i == 4 ? '5+' : i + 1,
  }));

  const [explicitPopulation, setExplicitPopulation] = useState(false);

  return (
    <StaticQuery
      query={graphql`
        query {
          allStrapiProperty {
            nodes {
              featured_image {
                childImageSharp {
                  fluid(maxWidth: 800, maxHeight: 500) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }

              gallery {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 800, maxHeight: 600) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              created_at
              available_from
              bedrooms
              bathrooms
              parks
              street_address
              address_2
              weekly_rent
              slug
              category
              location
              status
              strapiId
              hidden_from_website
            }
          }
        }
      `}
      render={data => {
        const allPropertiesUnsorted = data.allStrapiProperty.nodes
          .filter(p => !p.hidden_from_website)
          .filter(p => p.featured_image); // Only show properties that have an image defined
        allPropertiesUnsorted.forEach(p => {
          p.dateOrderable = format(new Date(p.created_at), 'yyyyMMdd');
        });

        const all_properties = [
          ...allPropertiesUnsorted
            .filter(p => p.status == 'available')
            .sort((p1, p2) => {
              if (p1.dateOrderable > p2.dateOrderable) return -1;
              return 1;
            }),
          ...allPropertiesUnsorted
            .filter(p => p.status != 'available')
            .sort((p1, p2) => {
              if (p1.dateOrderable < p2.dateOrderable) return 1;
              return -1;
            }),
        ].slice(0, 1000);

        if (properties.length == 0 && !explicitPopulation) {
          setExplicitPopulation(true);
          getMoreProperties(all_properties);
        }
        if (properties.length == 0 && sentFromNav) {
          getMoreProperties(all_properties);
          resetSentFromNav(false);
        }

        return (
          <div id="properties">
            <section className="section" style={{ paddingBottom: 0 }}>
              <div className="container">
                <StyledMobileToggle
                  className="columns is-hidden-desktop"
                  style={{ marginBottom: filterToggle ? '0.75rem' : 0 }}
                >
                  <div
                    className={`column is-full search-column is-hidden-desktop${
                      searchTerm.length > 1 ? ' selected' : ''
                    }`}
                    style={{ paddingBottom: 0 }}
                  >
                    <button className="button" onClick={() => filterToggleFn()}>
                      {filterToggle ? 'Clear Filters' : 'Show Filters'}
                    </button>
                    <div className="control has-icons-right">
                      <input
                        className="input is-uppercase mobile-search"
                        type="text"
                        placeholder="Search Properties"
                        value={searchTerm}
                        onChange={e => {
                          resetProperties();
                          applyFilter('searchTerm', e.target.value);
                          getMoreProperties(all_properties);
                        }}
                        onKeyUp={e => {
                          if (e.keyCode == 13) {
                            document.activeElement.blur();
                          }
                        }}
                      />
                      {searchTerm.length > 1 ? (
                        <a
                          className="icon is-small is-right"
                          onClick={e => {
                            e.preventDefault();
                            resetProperties();
                            applyFilter('searchTerm', '');
                            getMoreProperties(all_properties);
                          }}
                          style={{ pointerEvents: 'all', marginTop: '-2px' }}
                        >
                          <img src={SearchCloseIcon} style={{ width: '35%' }} />
                        </a>
                      ) : (
                        <span className="icon is-small is-right">
                          <img src={SearchIcon} style={{ width: '45%' }} />
                        </span>
                      )}
                    </div>
                  </div>
                </StyledMobileToggle>

                <StyledFilterBar
                  className={
                    'container ' +
                    (filterToggle ? '' : 'is-hidden-tablet is-hidden-mobile')
                  }
                  style={{
                    paddingTop: filterToggle ? '0.75rem' : 0,
                  }}
                >
                  <div className="columns is-mobile">
                    <div
                      className={`column first-tablet ${
                        category ? ' selected' : ''
                      } is-half-mobile is-half-tablet`}
                    >
                      <div className="field">
                      <Select
                        value={category}
                        placeholder="Type"
                        options={categoryOpt}
                        onChange={selection => {
                          resetProperties();
                          if (selection.value)
                            applyFilter('category', selection);
                          else applyFilter('category', null);
                          getMoreProperties(all_properties);
                        }}
                        className="react-select-container type"
                        classNamePrefix="react-select"
                        isSearchable={false}
                      />
                      </div>
                    </div>
                    <div
                      className={`column ${
                        location ? ' selected' : ''
                      } is-half-mobile is-half-tablet`}
                    >
                      <div className="field">
                      <Select
                        value={location}
                        placeholder="Location"
                        options={locationOpt}
                        onChange={selection => {
                          resetProperties();
                          if (selection.value)
                            applyFilter('location', selection);
                          else applyFilter('location', null);
                          getMoreProperties(all_properties);
                        }}
                        className="react-select-container type"
                        classNamePrefix="react-select"
                        isSearchable={false}
                      />
                      </div>
                    </div>
                    <div
                      className={`column is-narrow search-column is-hidden-mobile is-hidden-tablet is-visible-desktop ${
                        searchTerm.length > 1 ? ' selected' : ''
                      }`}
                    >
                      <div className="control has-icons-right">
                        <input
                          className="input"
                          type="text"
                          placeholder="Search Properties"
                          value={searchTerm}
                          onChange={e => {
                            resetProperties();
                            applyFilter('searchTerm', e.target.value);
                            getMoreProperties(all_properties);
                          }}
                        />
                        {searchTerm.length > 1 ? (
                          <a
                            className="icon is-small is-right"
                            onClick={() => {
                              resetProperties();
                              applyFilter('searchTerm', '');
                              getMoreProperties(all_properties);
                            }}
                            style={{ pointerEvents: 'all'}}
                          >
                            <img
                              src={SearchCloseIcon}
                              style={{ width: '35%' }}
                            />
                          </a>
                        ) : (
                          <span className="icon is-small is-right">
                            <img src={SearchIcon} style={{ width: '45%' }} />
                          </span>
                        )}
                      </div>
                    </div>
                    <div
                      className={`column ${
                        status ? ' selected' : ''
                      } is-half-mobile is-one-quarter-tablet`}
                    >
                      <div className="field">
                        <Select
                          value={status}
                          placeholder="Status"
                          options={statusOpt}
                          onChange={selection => {
                            resetProperties();
                            if (selection.value)
                              applyFilter('status', selection);
                            else applyFilter('status', null);
                            getMoreProperties(all_properties);
                          }}
                          className="react-select-container status"
                          classNamePrefix="react-select"
                          isSearchable={false}
                        />
                      </div>
                    </div>
                    <div
                      className={`column first-mobile ${beds ? ' selected' : ''
                        }   is-one-quarter-tablet`}
                    >
                      <div className="field">
                        <Select
                          value={beds}
                          placeholder="Bed"
                          options={[{ label: 'All', value: null }, ...bedOpt]}
                          onChange={selection => {
                            resetProperties();
                            if (selection.value) applyFilter('beds', selection);
                            else applyFilter('beds', null);
                            getMoreProperties(all_properties);
                          }}
                          className="react-select-container bed"
                          classNamePrefix="react-select"
                          isSearchable={false}
                        />
                      </div>
                    </div>
                    <div
                      className={`column ${
                        priceMin ? ' selected' : ''
                      } is-one-quarter-tablet`}
                    >
                      <div className="field">
                        <Select
                          value={priceMin}
                          placeholder="Min Price"
                          options={[
                            { label: 'All', value: null },
                            ...priceMinOpt,
                          ]}
                          onChange={selection => {
                            resetProperties();
                            if (selection.value)
                              applyFilter('priceMin', selection);
                            else applyFilter('priceMin', null);
                            getMoreProperties(all_properties);
                          }}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          isSearchable={false}
                        />
                      </div>
                    </div>
                    <div
                      className={`column ${
                        priceMax ? ' selected' : ''
                      } is-one-quarter-tablet`}
                    >
                      <div className="field">
                        <Select
                          value={priceMax}
                          placeholder="Max Price"
                          options={[
                            { label: 'All', value: null },
                            ...priceMaxOpt,
                          ]}
                          onChange={selection => {
                            resetProperties();
                            if (selection.value)
                              applyFilter('priceMax', selection);
                            else applyFilter('priceMax', null);
                            getMoreProperties(all_properties);
                          }}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          isSearchable={false}
                        />
                      </div>
                    </div>
                    
                  </div>
                </StyledFilterBar>
              </div>
            </section>

            <div
              className="section"
              id="scrollableContainer"
              style={{ padding: '0.75rem 1.5rem' }}
            >
              <div className="container">
                <InfiniteScroll
                  className="columns is-multiline"
                  dataLength={properties.length}
                  next={() => {
                    getMoreProperties(all_properties);
                  }}
                  scrollThreshold={0.666}
                  hasMore={properties.length < all_properties.length}
                >
                  {properties.map((p, i) => (
                    <GridItem p={p} i={i} key={p.strapiId} />
                  ))}
                </InfiniteScroll>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};

const mapStateToProps = ({
  properties,
  category,
  location,
  status,
  priceMin,
  priceMax,
  beds,
  baths,
  searchTerm,
  filterToggle,
  sentFromNav,
}) => ({
  properties,
  category,
  location,
  status,
  priceMin,
  priceMax,
  beds,
  baths,
  searchTerm,
  filterToggle,
  sentFromNav,
});

const mapDispatchToProps = dispatch => {
  return {
    resetProperties: () => {
      return dispatch({ type: `RESET` });
    },
    getMoreProperties: all_properties => {
      return dispatch({ type: `SCROLL`, all_properties });
    },
    applyFilter: (key, value) => {
      return dispatch({
        type: `FILTER`,
        key,
        value,
      });
    },
    filterToggleFn: () => {
      return dispatch({
        type: 'FILTER_TOGGLE',
      });
    },
    resetSentFromNav: () => {
      return dispatch({
        type: 'RESET_SENT_FROM_NAV',
      });
    },
  };
};

const HomePageGrid = connect(
  mapStateToProps,
  mapDispatchToProps,
)(HomePageGridComponent);

export default HomePageGrid;

const StyledFilterBar = styled('div')`
    
    padding: 0px;
    @media screen  and (max-width: 960px){
        padding-bottom: 20px;
    }
    .columns{
        justify-content: space-between;
        flex-wrap: wrap;
        @media screen and (max-width: 1024px){
            justify-content: center;
            
        }
        @media screen and (min-width: 1025px){
            padding-bottom: 0.75rem;
        }
        .column{
            @media screen and (max-width: 1300px){
                padding: 0.375em;
                padding-top: 0;
                padding-bottom: 0.75em;
                
            }
        }
    }
    select, input{
        background: #efefef;
        border: none;
        border-top: 2px solid #000;
        font-size: 13px;
        
    }
    input{
        &::placeholder{
            color: #999;
            opacity: 1;
        }
        &:hover,
        &:focus{
            border: none;
            border-top: 2px solid #000;
        }
        text-transform: uppercase;
        font-weight: 500;
        &[type="text"]{
          padding-bottom: 8px;
          height: 39px;
        }
        
    }
    .selected{
        .input{
            color: #fff;
            background-color: #000;
            border-color: #000;
        }
        
        
    }
    .search-column{
        width: 350px;
        @media screen and (max-width: 1420px){
            width: 220px;
        }

        @media screen and (max-width: 1200px){
            width: 220px;
        }
        @media screen and (max-width: 960px){
            width: 130px;
        }
    }

    input{
        @media screen and (max-width: 1216px){
            font-size: 0.9rem;
        }
        &:hover{
            cursor: pointer;
        }
        
        box-shadow: none;
    }

    .is-half-tablet, .is-one-quarter-tablet{
        @media screen and (min-width: 1024px){
            width: auto;
        }
    }
    
    .is-hidden-tablet{
        @media screen and (min-width: 1024px){
            display: block !important;
        }
    }
    &.is-hidden-tablet{
        @media screen and (min-width: 1024px){
            display: block !important;
        }
    }

    .react-select-container{
        min-width: 140px;
        width: 100%;
        display: inline-block;
        font-weight: 500;
        &.bed, &.bath{
            min-width: 90px;
        }
        &.type{
            @media screen and (max-width: 1220px){
                min-width: 90px;    
                .react-select__menu{
                    min-width: 123px;
                }
            }
        }
        &.status{
            @media screen and (max-width: 1220px){
                min-width: 100px;    
                .react-select__menu{
                    min-width: 123px;
                }
            }
        }
        
        .react-select__control{
            background: #efefef;
            width: 100%;
            border-radius: 0;
            border: none;
            display: flex;
            align-items: flex-start;

            &.react-select__control--is-focused{
                box-shadow: none;
            }
            .react-select__value-container{
                @media screen and (min-width: 1200px){
                  padding-top: 16px;
                }
                padding-top: 14px;
                border-top: 2px solid #000;
                font-size: 13px;
                &.react-select__value-container--has-value{
                    text-transform: uppercase;
                    border-top: #000;
                    
        
                    background: #000;
                    .react-select__single-value{
                        color: #fff;
                        
                    }
                }
                .react-select__placeholder{
                    color: #000;
                    text-transform: uppercase;
                }
                
            }
        }
        .react-select__indicator-separator{
            display: none;
        }
        
        .react-select__indicator{
            color: #000;
            
            border-top: 2px solid #000;
            
        }
        .react-select__menu{
            background: #efefef;
            z-index: 4;
            border-radius: 0;
            border: none;
            box-shadow: none;
            margin-top: 2px;

            .react-select__menu-list{
                padding: 0;
                
                .react-select__option{
                    text-transform: uppercase;
                    font-size: 0.75rem;
                    color: #999;
                    &.react-select__option--is-focused{
                        color: #999 !important;
                        background: none !important;
                    }
                    &.react-select__option--is-selected{
                        color: #000 !important;
                        background: none !important;
                    }
                    &:hover{
                      color: #000 !important;
                      cursor: pointer;
                    }
                }
            }
        }
        
    }

    .selected{
        .react-select-container{
            
            .react-select__control{
                background: #000;
            }
            .react-select__indicator{
                color: #fff;
                border-top: #000;
                background: #000;
            }
        }

    }
    &:hover{
        .react-select-container{
            .react-select__control{
                .react-select__value-container .react-select__placeholder{
                    transition: 0.5s;
                    opacity: 0.5;
                }
                &:hover{
                    .react-select__value-container, .react-select__placeholder{
                        opacity: 1;
                    }
                    cursor: pointer;
                }

            }

            
            
        }
    }

    @media screen and (max-width: 520px){
      .is-mobile{
        .column{
          // This is screwerd up
          // How to target first

          

          &:nth-of-type(odd){
            .field{
              margin-right: 0.33em;
            }
          }
          &:nth-of-type(even){
            .field{
              margin-left: 0.33em;
            }
          }
          &:nth-of-type(1){
            .field{
              margin-left: 0.33em;
              margin-right: 0;
            }

          }
          &:nth-of-type(2){
            .field{
              margin-right: 0.33em;
              margin-left: 0;
            }
          }
        }

        


      }
    }

    
      .is-mobile{
        .column{
          &:first-of-type{
            @media screen and (min-width: 1200px){
              margin-left: .33em; 
            }
            @media screen and (min-width: 1600px){
              margin-left: 0;
            }
          }
          &:last-of-type{
            @media screen and (min-width: 1200px){
              margin-right: .33em;
            }
            @media screen and (min-width: 1600px){
              margin-right: 0;
            }
          }

          
        }
      }
    }

    
    
}
`;

const StyledMobileToggle = styled('div')`
  .selected {
    .input {
      color: #fff;
      background-color: #000;
      border-color: #000;
    }
  }
  .input {
    box-shadow: none;
    border: none;
    border-top: 2px solid #000;
    text-transform: lowercase;
    padding: 2px 8px;
    &::placeholder {
      color: #000;
      opacity: 0.5;
    }
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .button {
    margin-top: 0;
    margin-bottom: 25px;
    font-size: 16px;

    width: 170px;
  }
  .mobile-search{
    background: none;
    &::placeholder{
      color: #999;
      opacity: 1;
    }
  }
`;
