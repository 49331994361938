import React from 'react';
import Layout from '../components/layout';
import Hero from '../components/hero';
import Grid from '../components/grid';
import HomePageGrid from '../components/homepageGrid'



const IndexPage = () => {

  return (

    <Layout isHome={true}>
      <Hero
        content={
          <h2 className="title is-uppercase">
            First<br />
            class<br />
            Property<br />management.
          </h2>
        }
        additionalClasses="is-medium"
      />
      <HomePageGrid isHome={true} />
    </Layout >
  );
};

export default IndexPage;
