import React from 'react';
import PropTypes from 'prop-types';

import 'swiper/css/swiper.css';
import Swiper from 'react-id-swiper';

import styled from '@emotion/styled';

import Img from 'gatsby-image';

import arrowLeft from '../images/arrow-left.svg';
import arrowRight from '../images/arrow-right.svg';
import arrowRightWhite from '../images/arrow-right-white.svg';
import close from '../images/close.svg';

class HeroSlideShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      swiper: null,
      lightBoxSwiper: null,
      slideIndex: 0,
      lightBoxShown: false,
      lightboxVariation: 'desktop',
      lightBoxAtStart: false,
      lightBoxNotScrolled: true,
      handlingKeyPress: false,
    };
  }

  lightBoxSwitch = () => {
    const { swiper, lightBoxShown } = this.state;

    // Opening Lightbox
    if (!lightBoxShown) {
      if (swiper != null) swiper.autoplay.stop();
      return this.setState({ lightBoxShown: true });
    }
    // Closing Lightbox
    else {
      if (swiper !== null) swiper.slideTo(0, 0);
      swiper.autoplay.start();
      return this.setState({
        lightBoxShown: false,
        slideIndex: 0,
        lightBoxIndex: 0,
        lightBoxNotScrolled: true,
      });
    }
  };

  // Higher Order function. You SHOULD NOT use anonymous functions in addEventListener because they will duplicate.
  // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Multiple_identical_event_listeners
  handler = e => {
    this.keyPress(e);
  };

  // ESC Key and Arrows for controlling both swipers
  keyPress = e => {
    const activeSwiper = this.state.lightBoxShown
      ? this.state.lightboxSwiper
      : this.state.swiper;
    if (!activeSwiper.destroyed) {
      if (e.keyCode === 37) activeSwiper.slidePrev(500); // Left Arrow
      if (e.keyCode === 39) activeSwiper.slideNext(500); // Right Arrow
      if (e.keyCode === 27 && this.state.lightBoxShown) this.lightBoxSwitch();
    }
  };

  componentWillUpdate() {
    document.addEventListener('keyup', this.handler);
  }
  componentWillUnmount() {
    document.removeEventListener('keyup', this.handler);
  }

  render() {
    const { slider, lightBox } = this.props;
    const { lightBoxShown, lightboxVariation, lightBoxAtStart } = this.state;

    const mainSliderParams = {
      preloadImages: false,
      centeredSlides: true,
      autoplay: { delay: 3750, stopOnLastSlide: true },
      on: {
        slideNextTransitionEnd: () =>
          this.setState({ slideIndex: this.state.slideIndex + 1 }),
        slidePrevTransitionEnd: () =>
          this.setState({ slideIndex: this.state.slideIndex - 1 }),
        click: this.lightBoxSwitch,
      },
      speed: ['hd', 'big', 'laptop'].includes(lightboxVariation) ? 400 : 600,
      initialSlide: 0,
      slidesPerGroupSkip: 0,
      getSwiper: swiper => this.setState({ swiper }),
    };

    const LightBoxParams = {
      preloadImages: false,
      centeredSlides: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      initialSlide: this.state.slideIndex,
      speed: ['hd', 'big', 'laptop'].includes(lightboxVariation) ? 400 : 600,
      on: {
        reachBeginning: () => this.setState({ lightBoxAtStart: true }),
        slideNextTransitionEnd: () =>
          this.setState({ lightBoxAtStart: false, lightBoxNotScrolled: false }),
      },
      getSwiper: swiper => this.setState({ lightboxSwiper: swiper }),
    };
    return (
      <>
        <StyledSlider className={`slider full-height`}>
          <Swiper {...mainSliderParams}>
            {slider.map((i, k) => (
              <div key={k}>
                <Img fluid={i} style={{ cursor: 'pointer' }} />
              </div>
            ))}
          </Swiper>
        </StyledSlider>

        {lightBoxShown ? (
          <StyledLightBox
            className={`lightbox ${
              lightBoxAtStart ||
              (this.state.slideIndex === 0 && this.state.lightBoxNotScrolled)
                ? 'start'
                : ''
            }`}
          >
            <Swiper {...LightBoxParams}>
              {lightBox.map((i, k) => (
                <div key={k}>
                  <div style={{ justifyContent: 'center', display: 'flex' }}>
                    <Img
                      fixed={i[lightboxVariation]}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                </div>
              ))}
            </Swiper>
            <button
              className="button close"
              onClick={this.lightBoxSwitch}
            ></button>
          </StyledLightBox>
        ) : (
          <></>
        )}
      </>
    );
  }

  /**
   * Calculate & Update state of new dimensions
   */
  updateDimensions = () => {
    let lightboxVariation = 'hd';
    if (window.innerWidth < 1920) lightboxVariation = 'big';
    if (window.innerWidth < 1650) lightboxVariation = 'laptop';
    if (window.innerWidth < 1250) lightboxVariation = 'tablet';
    if (window.innerWidth < 420) lightboxVariation = 'mobile';
    this.setState({ lightboxVariation });
  };

  /**
   * Add event listener
   */
  componentDidMount = () => {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  };

  /**
   * Remove event listener
   */
  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateDimensions);
  };
}

HeroSlideShow.propTypes = {
  // Fluid objects from graphQL gatsby fluid fragment
  slider: PropTypes.arrayOf(PropTypes.object).isRequired,
  lightBox: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const StyledSlider = styled('div')`
  .swiper-slide {
    .gatsby-image-wrapper {
      & > div {
        padding: 0 !important;
        height: 100vh;
        @media screen and (orientation: portrait) {
          height: 50vh;
        }
        @media screen and (orientation: portrait) and (max-width: 520px) {
          height: 280px;
        }
      }
    }
  }
  .hero-gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 8;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) 15%);
    cursor: pointer;
  }
`;

const StyledLightBox = styled('div')`
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: #efefef;
    width: 100vw;
    padding: 0 2vw;
    overflow: hidden;
    display: flex;
    align-items: center;

    @media screen and (max-width: 1024px){
        padding: 0 2.5vw;
    }
    @media screen and (max-width: 520px){
        padding: 0
    }
    z-index: 999;
    overflow: hidden;
    .swiper-container{
        max-width: 100vw;
        max-height: 95vh;
    }

    .swiper-wrapper{
        align-items: center;
        
    }

    &.start{
        @media screen and (max-width: 520px){
            .swiper-button-next{
                background-image: url(${arrowRightWhite});
            }
        }
    }

    


    .swiper-button-next, .swiper-button-prev{
        color: #000;
        height: 30px;
        width: 30px;
        margin-top: -15px;
        &.swiper-button-disabled{
            opacity: 0;
        }
        &:after{
            display: block;
            content: ' ';
            background-size: 30px 30px;
            height: 30px;
            width: 30px;
        }

        @media screen and (max-width: 520px){
          height: 50px;
          width: 50px;
          margin-top: -25px;
          background-size: 30px 30px;
          background-repeat: no-repeat;
          background-position: center center;
          &:after{
              display: block;
              content: ' ';
              background-size: 30px 30px;
              height: 30px;
              width: 30px;
          }
        }
    }
    .swiper-button-next{
        background-image: url(${arrowRight});
    }
    .swiper-button-prev{
        background-image: url(${arrowLeft});
        
    }
    .close{
        position: absolute;
        top: 10px;
        right 20px;
        z-index:9999;
        @media screen and (max-width: 1024px){
            right: 10px;
        }
        height: 30px;
        width: 30px;
        padding: 0;
        border: none;
        background-image: url(${close});
        &.swiper-button-disabled{
            opacity: 0;
        }
        &:after{
            display: block;
            content: ' ';
            background-size: 30px 30px;
            height: 30px;
            width: 30px;
        }
        
    }
`;

export default HeroSlideShow;
